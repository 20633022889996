'use strict';
(function ($) {
	window.dataLayer = window.dataLayer || [];

	T.Utils = T.Utils || {};

	T.Utils.Tracking = {

		_consentKeys: {
			piwik: "Piwik Pro",
			gtm: "GTM"
		},
		_ecommerceSessionKey: "ecommerce.model.",

		CHECKOUT: "product_checkout",
		ORDER: "product_order",
		ADD: "product_add",
		REMOVE: "product_remove",

		enableGA4: $('[id="gtm"]') || false,

		EVENTS: {
			BEGIN_CHECKOUT: "begin_checkout",
			PURCHASE: "purchase",
			ADD_TO_CART: "add_to_cart",
			REMOVE_FROM_CART: "remove_from_cart",
			ADD_SHIPPING_INFO: "add_shipping_info",
			ADD_PAYMENT_INFO: "add_payment_info",
			CROSS_SALE: "cross_sale"
		},

		ECOMMERCE_EVENTS: {
			BEGIN_CHECKOUT: "begin_checkout",
			PURCHASE: "purchase",
			ADD_TO_CART: "add_to_cart",
			REMOVE_FROM_CART: "remove_from_cart",
			ADD_SHIPPING_INFO: "add_shipping_info",
			ADD_PAYMENT_INFO: "add_payment_info",
		},

		GA4_PIWIK_MAPPING_EVENTS: {
			product_checkout: "begin_checkout",
			product_order: "purchase",
			product_add: "add_to_cart",
			product_remove: "remove_from_cart",
			add_shipping_info: "add_shipping_info",
			add_payment_info: "add_payment_info"
		},

		GA4_ONLY_NO_PREFIX: { sendPiwik: false, sendGA4: true, prefix: false },
		GA4_ONLY: { sendPiwik: false, sendGA4: true, prefix: true },
		PIWIK_ONLY: { sendPiwik: true, sendGA4: false },
		GA4_PREFIX: 'EEC_',
		ITEM_TYPE_MEMBERSHIP: 'Mitgliedschaft',
		ITEM_TYPE_INSURANCE: 'Versicherung',

		ga4TrackingStatus: {},

		getEcommerceModel: function (productType) {
			const storedModel = T.Utils.Store.get(this._ecommerceSessionKey + productType, T.Utils.Store.SESSION);
			if (storedModel && storedModel.productType === productType) {
				return storedModel;
			}
			return this._createEmptyEcommerceModel();
		},

		setEcommerceModel: async function (model) {
			this._piwikConsent = await T.Utils.Helper.isConsentMatchAsync(this._consentKeys.piwik);
			this._gtmConsent = await T.Utils.Helper.isConsentMatchAsync(this._consentKeys.gtm);

			if (this._piwikConsent || this._gtmConsent) {
				T.Utils.Store.set(this._ecommerceSessionKey + model.productType, model, T.Utils.Store.SESSION);
			}
		},

		push: function (model, event, options = { sendPiwik: true, sendGA4: true, prefix: true }, userData = undefined) {
			const products = Array.isArray(model) ? model.map(this._getProductInformations) : [this._getProductInformations(model)];
			const result = { product: products, event: event };

			if (event === this.ORDER) {
				result.order = Array.isArray(model) ? this._getOrderInformations(model[0]) : this._getOrderInformations(model);
			}

			return this.pushData(result, options, userData); //keep return for possibility to wait for dataLayer push
		},

		pushEvent: function (model, event) {
			const eventDataArray = Array.isArray(model)
				? model.map(this._getEventInformation)
				: [this._getEventInformation(model)];

			eventDataArray.forEach(eventData => {
				this.pushData({
					"eventData": eventData,
					"event": event
				});
			});
		},

		pushData: async function (data, options = { sendPiwik: true, sendGA4: true }, userData = undefined) {
			this._piwikConsent = await T.Utils.Helper.isConsentMatchAsync(this._consentKeys.piwik);
			this._gtmConsent = await T.Utils.Helper.isConsentMatchAsync(this._consentKeys.gtm);

			if (options.sendPiwik && this._piwikConsent) {
				dataLayer.push(data);
			}

			if (options.sendGA4 && this.enableGA4 && this._gtmConsent) {
				const ga4Event = this.GA4_PIWIK_MAPPING_EVENTS[data.event] || this.EVENTS[data.event] || this.EVENTS[data.event.toUpperCase()];
				if (ga4Event) {
					this._sendGA4Event(data, options, userData);
				}
			}
		},

		pushKameleoonData: function () {
			window.kameleoonQueue = window.kameleoonQueue || [];
			window.kameleoonQueue.push(() => {
				Kameleoon.API.Core.load();
			});
		},

		hasAnalyticsConsent: function (callback) {
			this._checkConsentAndExecute(this._consentKeys.piwik, callback); //Click tracking is currently only working with piwki NOSONAR
		},

		_sendGA4Event: function (data, options = { prefix: true }, userData = undefined) {
			const ga4Event = this.GA4_PIWIK_MAPPING_EVENTS[data.event] || data.event;
			const eventData = data.order
				? this._mapToGA4EcommerceFormat(data.product, data.order, data.event, userData)
				: this._mapToGA4EcommerceFormat(data.product, null, data.event, userData);

			const ecommerceEventName = `${options.prefix ? this.GA4_PREFIX : ''}${ga4Event}`;
			const event = ga4Event === this.EVENTS.CROSS_SALE ? `custom_event` : ecommerceEventName;

			this._clearAddAndPushRemoveEventIfNecessary(ga4Event);
			if (this._eventSendingAllowed(ga4Event)) {
				this._pushToDataLayer(event, ga4Event, eventData);
			}
			this._persistAddAndRemoveEvents(ga4Event, eventData);
			this._clearEventsIfNecessary(ga4Event);
		},

		_clearAddAndPushRemoveEventIfNecessary: function _clearAddAndPushRemoveEventIfNecessary(ga4Event) {
			if (!this.isEventSendAllowed(ga4Event) && ga4Event === this.EVENTS.ADD_TO_CART && this._previousAddEvent) {
				const eventName = `${this.GA4_PREFIX}${this.EVENTS.REMOVE_FROM_CART}`;
				this._pushToDataLayer(eventName, this.EVENTS.REMOVE_FROM_CART, this._previousAddEvent.eventData);
				this._clearEventsIfNecessary(this.EVENTS.REMOVE_FROM_CART);
			}
		},

		_pushToDataLayer: function _pushToDataLayer(event, ga4Event, eventData) {
			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
			dataLayer.push({
				event: event,
				event_name: ga4Event,
				...eventData
			});
		},

		_persistAddAndRemoveEvents: function _persistAddAndRemoveEvents(ga4Event, eventData) {
			if (ga4Event === this.EVENTS.ADD_TO_CART || ga4Event === this.EVENTS.REMOVE_FROM_CART) {
				this._previousAddEvent = {
					ga4Event,
					eventData
				};
			}
		},

		_clearEventsIfNecessary: function _clearEventsIfNecessary(eventName) {
			if (eventName === this.EVENTS.REMOVE_FROM_CART) {
				this.ga4TrackingStatus[this.EVENTS.ADD_TO_CART] = false;
			}
			if (eventName === this.EVENTS.ADD_TO_CART) {
				this.ga4TrackingStatus[this.EVENTS.REMOVE_FROM_CART] = false;
			}
		},

		isEventSendAllowed: function isEventSendAllowed(eventName) {
			return !this.ga4TrackingStatus[eventName];
		},

		_eventSendingAllowed: function _eventSendingAllowed(eventName) {
			if (Object.keys(this.ga4TrackingStatus).length === 0) {
				Object.keys(this.EVENTS).forEach(eventKey => {
					this.ga4TrackingStatus[this.EVENTS[eventKey]] = false;
				});
			}
			if (this.isEventSendAllowed(eventName)) {
				this.ga4TrackingStatus[eventName] = true;
				return true;
			}
			return false;
		},

		_mapEcommerceEvent: function _mapEcommerceEvent(ga4Event, items, products, order, userData) {
			const ecommerce = {
				currency: "EUR",
				items: items,
				value: parseFloat(this.getTotalPrice(products).toFixed(2))
			};

			if (ga4Event === this.EVENTS.ADD_PAYMENT_INFO) {
				ecommerce.payment_type = products[0]?.checkOutPaymentMethode && products[0]?.checkOutPaymentMethode !== '' ? products[0]?.checkOutPaymentMethode : undefined;
			}

			if ((ga4Event === this.ORDER || ga4Event === this.EVENTS.PURCHASE) && order) {
				ecommerce.transaction_id = order.orderID;
				ecommerce.membership = this.hasMembershipItem(items) ? 'true' : 'false';
				ecommerce.value = parseFloat(parseFloat(order.orderTotalPrice).toFixed(2));
				ecommerce.payment_type = order.checkOutPaymentMethode && order.checkOutPaymentMethode !== '' ? order.checkOutPaymentMethode : undefined;
			
				if (userData) {

					if (userData.user_phone) {
						ecommerce.user_phone = window.sha256(userData.user_phone);
					}
					if (userData.user_mail) {
						ecommerce.user_mail = window.sha256(userData.user_mail);
					}
				}
			}

			return ecommerce;
		},

		_mapToGA4EcommerceFormat: function (products, order, event, userData) {
			const result = {};
			const items = Array.isArray(products) ? products.map(this._mapProduct) : [this._mapProduct(products)];
			const ga4Event = this.GA4_PIWIK_MAPPING_EVENTS[event] || event;

			if (this._isEcommerceEvent(ga4Event)) {
				result.ecommerce = this._mapEcommerceEvent(ga4Event, items, products, order, userData);
			} else if (ga4Event === this.EVENTS.CROSS_SALE && this.hasInsuranceItem(items) && this.hasMembershipItem(items)) {
				const crossSale = {
					membership: 'true',
					cross_sale_department: this._getInsuranceProductName(items),
					cross_sale_item: this.ITEM_TYPE_MEMBERSHIP,
				};
				result.cross_sale = crossSale;
			}

			return result;
		},

		_isEcommerceEvent: function _isEcommerceEvent(eventName) {
			return eventName && eventName !== '' && eventName === this.ECOMMERCE_EVENTS[eventName.toUpperCase()];
		},

		_mapProduct: function (product) {
			return {
				item_name: product.productName,
				item_id: product.productID,
				price: isNaN(parseFloat(product.productPrice)) ? undefined : parseFloat(parseFloat(product.productPrice).toFixed(2)),
				quantity: parseInt(product.productQty, 10),
				item_brand: product.productBrand,
				item_category: product.productCategory,
				item_variant: product.productVariant && product.productVariant !== '' ? product.productVariant : undefined,
				// index: product.productListPosition, // keep for later usage NOSONAR
				// item_list_name: product.productList, // keep for later usage NOSONAR
				item_type: product.productType
				// contract_type: "auto renewal", // keep for later usage NOSONAR
			};
		},

		_getProductInformations: function (model) {
			return {
				productName: model.productName,
				productID: model.productID,
				productStatus: model.productStatus,
				productPrice: model.productPrice,
				productVariant: model.productVariant,
				productBrand: model.productBrand,
				productCategory: model.productCategory,
				productList: model.productListPosition,
				productType: model.productType,
				contractType: model.contractType,
				productQty: model.productQty,
				checkOutStep: model.checkOutStep,
				checkOutPaymentMethode: model.checkOutPaymentMethod
			};
		},

		_getOrderInformations: function (model) {
			return {
				orderID: model.orderID,
				orderTotalPrice: model.orderTotalPrice,
				orderStatus: model.orderStatus,
				checkOutPaymentMethode: model.checkOutPaymentMethod
			};
		},

		_getEventInformation: function (model) {
			return {
				eventCategory: model.eventCategory,
				eventAction: model.eventAction,
				eventLabel: model.eventLabel,
				eventValue: model.eventValue
			};
		},

		updateEventClickData: function (element, eventData) {
			const $element = $(element);
			$element.attr('data-clickevent', JSON.stringify(eventData));
		},

		_createEmptyEcommerceModel: function () {
			return {
				productName: "",
				productID: "",
				productStatus: "",
				productPrice: "",
				productQty: "",
				productCategory: "",
				productType: "",
				productVariant: "",
				productList: "",
				productListPosition: 0,
				productBrand: "",
				checkOutStep: "",
				checkOutPaymentMethod: "",
				contractType: "",
				orderID: "",
				orderTotalPrice: "",
				orderStatus: ""
			};
		},

		_checkConsentAndExecute: function (consentKey, callback) {
			T.Utils.Helper.isConsentMatch(consentKey, callback);
		},

		_getInsuranceProductName: function (items) {
			const insurance = items.find(item => item.item_type === this.ITEM_TYPE_INSURANCE);
			return insurance && insurance.item_name ? insurance.item_name : '';
		},

		hasMembershipItem: function (items) {
			return items.some(item => item.item_type === this.ITEM_TYPE_MEMBERSHIP);
		},

		hasInsuranceItem: function (items) {
			return items.some(item => item.item_type === this.ITEM_TYPE_INSURANCE);
		},

		getTotalPrice: function (items) {
			return parseFloat(items.reduce((total, item) => total + (parseFloat(item.productPrice) || 0), 0));
		}
	};
})(jQuery);